import React, { forwardRef, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import CustomPopup from '../../components/BtAdminPanel/CustomPopup';
import { IAudit } from '../../utils/Audit/audit.interface';
import AuditAPI from '../../utils/Audit/audit';
import Pagination from '../../library/Pagination/Pagination';
import Button from '../../library/Button/button';
import Search from '../../components/Search';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useWindowSize } from '../../library/WindowSize/windowSize';
import { FederatedData } from '../../utils/Audit/federatedIdentity.interface';
import { UserType } from '../../utils/Helper/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { LoginContext } from '../../context/IppContext';
import ConditionalLoader from '../../components/ConditionalLoader';
import { IPPParagraph } from '../../library/Heading/Heading';

/**
 * The interface uses an index signature to represent a dictionary-like structure.
 * @userName - assumed to be normalized (e.g., lowercase, trimmed).
 * @values - Objects containing arbitrary key-value pairs related to the user's federated identity.
 * The value can be undefined if no federated data is available for a specific username.
 */

const AuditScreen: React.FC = () => {
  const { setTokenExpired } = useContext(LoginContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAuditData, setSelectedAuditData] = useState('');
  const [selectedAuditDataModule, setSelectedAuditDataModule] = useState<IAudit | null>(null);
  const [federatedData, setFederatedData] = useState<FederatedData>({});
  const [auditData, setAuditData] = useState<IAudit[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredOrgNameRow, setHoveredOrgNameRow] = useState(-1);
  const [hoveredUserTypeRow, setHoveredUserTypeRow] = useState(-1);
  const [showModuleListPopup, setShowModuleListPopup] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedLoginStartDate, setSelectedLoginStartDate] = useState<Date | null>(null);
  const [selectedLoginEndDate, setSelectedLoginEndDate] = useState<Date | null>(null);
  const [selectedFederatedIdentityStartDate, setSelectedFederatedIdentityStartDate] =
    useState<Date | null>(null);
  const [selectedFederatedIdentityEndDate, setSelectedFederatedIdentityEndDate] =
    useState<Date | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const showTable = selectedLoginStartDate !== null || selectedFederatedIdentityStartDate !== null;

  // Get screen size using the custom hook
  const { width: windowWidth } = useWindowSize();

  // Define pageSize based on screen size using breakpoints
  const pageSize = useMemo(() => {
    if (windowWidth >= 1280) {
      return 10;
    } else if (windowWidth >= 768) {
      return 5;
    } else {
      return 3;
    }
  }, [windowWidth]);

  useEffect(() => {
    const fetchData = async () => {
      // Check if either the login dates or both federated identity dates are provided
      if (
        (!selectedLoginStartDate || !selectedLoginEndDate) &&
        (!selectedFederatedIdentityStartDate || !selectedFederatedIdentityEndDate)
      ) {
        return; // Prevent API call if no valid dates are selected
      }

      setIsLoading(true);
      try {
        const auditAPI = new AuditAPI();

        // Prepare the API call parameters, ensuring they are valid Date objects
        const loginStartDate = selectedLoginStartDate
          ? formatDateForAPI(selectedLoginStartDate)
          : undefined;
        const loginEndDate = selectedLoginEndDate
          ? formatDateForAPI(selectedLoginEndDate)
          : undefined;
        const federatedIdentityStartDate = selectedFederatedIdentityStartDate
          ? formatDateForAPI(selectedFederatedIdentityStartDate)
          : undefined;
        const federatedIdentityEndDate = selectedFederatedIdentityEndDate
          ? formatDateForAPI(selectedFederatedIdentityEndDate)
          : undefined;

        // Call the API with the prepared parameters
        const result = await auditAPI.getAuditData(
          loginStartDate!,
          loginEndDate!,
          federatedIdentityStartDate!,
          federatedIdentityEndDate!,
          setTokenExpired
        );

        const filteredResult = result.filter((item) => item !== null) as IAudit[];
        setAuditData(filteredResult);
        const federatedDataMap = extractFederatedData(filteredResult);
        setFederatedData(federatedDataMap);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, [
    selectedLoginStartDate,
    selectedLoginEndDate,
    selectedFederatedIdentityStartDate,
    selectedFederatedIdentityEndDate
  ]);

  const formatDateForAPI = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
  };

  const extractFederatedData = (data: (IAudit | null)[]): FederatedData => {
    const federatedDataMap: FederatedData = {};

    data.forEach((item) => {
      if (item?.federateIdentityHistory && Object.keys(item.federateIdentityHistory).length > 0) {
        const orgEmail = item.federateIdentityHistory.orgEmail;

        // Store federatedIdentityHistory entries for the user by orgEmail
        if (!federatedDataMap[orgEmail]) {
          federatedDataMap[orgEmail] = [];
        }
        federatedDataMap[orgEmail].push({
          ...item.federateIdentityHistory,
          createdFederatedIdentityAt:
            item.createdFederatedIdentityAt && new Date(item.createdFederatedIdentityAt).toString()
        });
      } else if (item?.userName && item?.orgUserList) {
        // Check if orgUserList contains an email that matches the userName
        const matchingUser = item.orgUserList.find((user) => user.email === item.userName);
        if (matchingUser) {
          // Find the corresponding federateIdentityHistory based on orgEmail
          const federatedHistoryEntries = data
            .filter(
              (auditItem) =>
                auditItem?.federateIdentityHistory &&
                auditItem.federateIdentityHistory.orgEmail === matchingUser.email
            )
            .map((auditItem) => ({
              ...auditItem?.federateIdentityHistory,
              createdFederatedIdentityAt:
                auditItem?.createdFederatedIdentityAt &&
                new Date(auditItem?.createdFederatedIdentityAt).toString()
            }));

          if (federatedHistoryEntries.length > 0) {
            // Sort the federatedHistoryEntries by createdFederatedIdentityAt, descending
            federatedHistoryEntries.sort((a, b) => {
              const dateA = a.createdFederatedIdentityAt
                ? new Date(a.createdFederatedIdentityAt)
                : new Date(0);
              const dateB = b.createdFederatedIdentityAt
                ? new Date(b.createdFederatedIdentityAt)
                : new Date(0);
              return dateB.getTime() - dateA.getTime(); // Most recent first
            });

            federatedDataMap[matchingUser.email] = federatedHistoryEntries;
          }
        }
      }
    });

    // Now, ensure each entry in federatedDataMap is sorted correctly
    Object.keys(federatedDataMap).forEach((orgEmail) => {
      federatedDataMap[orgEmail].sort((a, b) => {
        const dateA = a.createdFederatedIdentityAt
          ? new Date(a.createdFederatedIdentityAt)
          : new Date(0);
        const dateB = b.createdFederatedIdentityAt
          ? new Date(b.createdFederatedIdentityAt)
          : new Date(0);
        return dateB.getTime() - dateA.getTime(); // Most recent first
      });
    });

    return federatedDataMap;
  };

  const filteredData = useMemo(() => {
    return auditData
      .filter((item) => {
        if (!item?.orgName) {
          return false;
        }

        // Using optional chaining to safely access properties
        const searchMatches =
          (item.orgName?.toLowerCase().includes(searchInput.toLowerCase()) ?? false) ||
          (item.userName?.toLowerCase().includes(searchInput.toLowerCase()) ?? false);

        return searchMatches;
      })
      .sort((a, b) => {
        // Ensure loginDate is valid before sorting
        const currentItemLoginDate = new Date(a.loginDate);
        const nextItemLoginDate = new Date(b.loginDate);

        // Check if both dates are valid
        if (isNaN(currentItemLoginDate.getTime()) && isNaN(nextItemLoginDate.getTime())) {
          return 0;
        } else if (isNaN(currentItemLoginDate.getTime())) {
          return 1;
        } else if (isNaN(nextItemLoginDate.getTime())) {
          return -1;
        }

        // Sort by loginDate in descending order (latest date first)
        return nextItemLoginDate.getTime() - currentItemLoginDate.getTime();
      });
  }, [searchInput, auditData]);

  // Calculate startIndex and endIndex based on currentPage and pageSize
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);

  // Slice the filteredData for the current page
  const currentAuditData = filteredData.slice(startIndex, endIndex);

  const tablePadding = (() => {
    switch (currentAuditData.length) {
      case 1:
        return 'mt-[20.7rem]';
      case 2:
        return 'mt-[15.3rem]';
      case 3:
        return 'mt-[10.2rem]';
      case 4:
        return 'mt-[7.6rem]';
      default:
        return 'mt-[4rem]';
    }
  })();

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
    setShowAlert(e.target.value !== '');
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleStatusClick = (userName: string) => {
    setSelectedAuditData(userName);
    setShowPopup(true);
  };

  const handleModuleClick = (item: IAudit) => {
    setSelectedAuditDataModule(item);
    setShowModuleListPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // Function to disable dates after current date
  const isDateDisabled = (date: Date) => {
    return date < new Date();
  };

  const filterDataForLogin = (date: Date) => {
    // Disable all dates before start date + 1 day and after start date + 15 days
    if (selectedLoginStartDate) {
      const startPlusOne = new Date(selectedLoginStartDate);
      startPlusOne.setDate(startPlusOne.getDate() + 1);
      const startPlusFifteen = new Date(selectedLoginStartDate);
      startPlusFifteen.setDate(startPlusFifteen.getDate() + 15);

      return date >= startPlusOne && date <= startPlusFifteen;
    } else {
      return false; // Disable all dates if start date is not selected
    }
  };

  const filterDataForFederatedIdentity = (date: Date) => {
    // Disable all dates before start date + 1 day and after start date + 15 days
    if (selectedFederatedIdentityStartDate) {
      const startPlusOne = new Date(selectedFederatedIdentityStartDate);
      startPlusOne.setDate(startPlusOne.getDate() + 1);
      const startPlusFifteen = new Date(selectedFederatedIdentityStartDate);
      startPlusFifteen.setDate(startPlusFifteen.getDate() + 15);

      return date >= startPlusOne && date <= startPlusFifteen;
    } else {
      return false; // Disable all dates if start date is not selected
    }
  };

  const handleLoginStartDateChange = (date: Date | null) => {
    setSelectedLoginStartDate(date);
    if (date) {
      const endDate = new Date(date);
      endDate.setDate(endDate.getDate() + 15);
      setSelectedLoginEndDate(endDate);
    } else {
      setSelectedLoginEndDate(null);
    }
  };

  const handleLoginEndDateChange = (date: Date | null) => {
    setSelectedLoginEndDate(date);
    // Don't allow the user to change the end date
    if (selectedLoginStartDate && date && date < selectedLoginStartDate) {
      setSelectedLoginEndDate(null);
      setShowErrorPopup(true); // Show error popup if end date is before start date
    } else {
      setShowErrorPopup(false); // Hide error popup if end date is valid
    }
  };

  const handleFederatedIdentityStartDateChange = (date: Date | null) => {
    setSelectedFederatedIdentityStartDate(date);
    if (date) {
      const endDate = new Date(date);
      endDate.setDate(endDate.getDate() + 15);
      setSelectedFederatedIdentityEndDate(endDate);
    } else {
      setSelectedFederatedIdentityEndDate(null);
    }
  };

  const handleFederatedIdentityEndDateChange = (date: Date | null) => {
    setSelectedFederatedIdentityEndDate(date);
    // Don't allow the user to change the end date
    if (selectedFederatedIdentityStartDate && date && date < selectedFederatedIdentityStartDate) {
      setSelectedFederatedIdentityStartDate(null);
      setShowErrorPopup(true); // Show error popup if end date is before start date
    } else {
      setShowErrorPopup(false); // Hide error popup if end date is valid
    }
  };

  return (
    <div className="w-full md:w-full">
      <div className="bg-white block md:w-[100%] mx-auto md:ps-0.5 md:pe-0.5">
        <br />
        <>
          {showAlert && (
            <div className="bg-white border-l-4 border-red text-red p-4" role="alert">
              <p className="font-bold">
                Tip for Searching: You can search across all table fields except for the User Type
                and login Timestamp.
              </p>
              {searchInput && <p>Searching for: {searchInput}</p>}
            </div>
          )}
          <div className="w-full max-h-screen overflow-y-scroll">
            <div className="flex justify-center flex-wrap items-center gap-4">
              <div className="flex flex-col">
                <label className="visually-hidden text-slate-950">Select Login Dates:</label>
                <DatePicker
                  selected={selectedLoginStartDate}
                  onChange={handleLoginStartDateChange}
                  className="w-72 mt-3.5"
                  disabledKeyboardNavigation
                  filterDate={isDateDisabled}
                  customInput={
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full px-4 py-2 border rounded-md"
                        placeholder="Start date to filter login records"
                        value={
                          selectedLoginStartDate ? selectedLoginStartDate.toLocaleDateString() : ''
                        }
                        onChange={(e) => e.preventDefault()}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="absolute top-2 right-3 text-gray-500"
                        size="lg"
                      />
                    </div>
                  }
                />
                <DatePicker
                  selected={selectedLoginEndDate}
                  onChange={handleLoginEndDateChange}
                  className="w-72 mt-3.5"
                  disabledKeyboardNavigation
                  filterDate={filterDataForLogin}
                  customInput={
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full px-4 py-2 border rounded-md"
                        placeholder="End date to filter login records"
                        value={
                          selectedLoginEndDate ? selectedLoginEndDate.toLocaleDateString() : ''
                        }
                        onChange={(e) => e.preventDefault()}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="absolute top-2 right-3 text-gray-500"
                        size="lg"
                      />
                    </div>
                  }
                />
              </div>
              <div className="flex flex-col">
                <label className="visually-hidden text-slate-950">
                  Select Federated Identity Dates:
                </label>
                <DatePicker
                  selected={selectedFederatedIdentityStartDate}
                  onChange={handleFederatedIdentityStartDateChange}
                  className="w-96 mt-3.5"
                  disabledKeyboardNavigation
                  filterDate={isDateDisabled}
                  customInput={
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full px-4 py-2 border rounded-md"
                        placeholder="Start date to filter Federated Identity records"
                        value={
                          selectedFederatedIdentityStartDate
                            ? selectedFederatedIdentityStartDate.toLocaleDateString()
                            : ''
                        }
                        onChange={(e) => e.preventDefault()}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="absolute top-2 right-3 text-gray-500"
                        size="lg"
                      />
                    </div>
                  }
                />
                <DatePicker
                  selected={selectedFederatedIdentityEndDate}
                  onChange={handleFederatedIdentityEndDateChange}
                  className="w-96 mt-3.5"
                  disabledKeyboardNavigation
                  filterDate={filterDataForFederatedIdentity}
                  customInput={
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full px-4 py-2 border rounded-md"
                        placeholder="End date to filter Federated Identity records"
                        value={
                          selectedFederatedIdentityEndDate
                            ? selectedFederatedIdentityEndDate.toLocaleDateString()
                            : ''
                        }
                        onChange={(e) => e.preventDefault()}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="absolute top-2 right-3 text-gray-500"
                        size="lg"
                      />
                    </div>
                  }
                />
              </div>
            </div>
            {!showTable && (
              <div className="h-72 font-bold flex justify-center items-end">
                <IPPParagraph
                  className="text-lg text-center"
                  headerText="Select a date range to view audit logs for login and federated identity."
                />
              </div>
            )}
            {showTable && (
              <ConditionalLoader isLoading={isLoading}>
                <div className="mt-2 mb-1">
                  <Search onChange={handleSearchInputChange} text="Search by others..." hideLabel />
                </div>
                <div className="min-w-fit border rounded-md overflow-hidden">
                  <table className="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                    <thead className="bg-[#f8f8f8]">
                      <tr className="text-black text-center">
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          Organisation Name{' '}
                        </th>
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          User Name{' '}
                        </th>
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          Login Timestamp{' '}
                        </th>
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          User Type{' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {currentAuditData.map((item: IAudit | null, index: number) =>
                        item?.orgName !== undefined ? (
                          <tr key={index}>
                            <td className="px-6 py-4 text-center">
                              <Button
                                onclick={() => handleModuleClick(item)}
                                onMouseEnter={() => setHoveredOrgNameRow(index)}
                                onMouseLeave={() => setHoveredOrgNameRow(-1)}>
                                <span className="text-blue-500 cursor-pointer">
                                  {item?.orgName}
                                </span>
                                {hoveredOrgNameRow === index && (
                                  <div className="group flex relative">
                                    <span className="px-1 py-1 group-hover:opacity-100 transition -opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2 -translate-x-1/2 translate-y-full m-4 mx-auto">
                                      Click for Module List
                                    </span>
                                  </div>
                                )}
                              </Button>
                            </td>
                            <td className="px-6 py-4 text-center">{item?.userName}</td>
                            <td className="px-6 py-4 text-center">
                              {new Date(item?.loginDate).toString()}
                            </td>
                            <td className="px-6 py-4 text-center">
                              <Button
                                onclick={() => handleStatusClick(item?.userName || '')}
                                onMouseEnter={() => setHoveredUserTypeRow(index)}
                                onMouseLeave={() => setHoveredUserTypeRow(-1)}>
                                <span className="text-blue-500 cursor-pointer">
                                  {item?.userType === UserType.BtAdmin
                                    ? 'BT Admin'
                                    : item?.userType === UserType.OrganizationAdmin
                                    ? 'Organisation Admin'
                                    : item?.userType === UserType.OrganizationUser
                                    ? 'User  '
                                    : ''}
                                </span>
                                {hoveredUserTypeRow === index && (
                                  <div className="group flex relative">
                                    <span className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2 -translate-x-1/2 translate-y-full m-4 mx-auto">
                                      Click for Federated Identity
                                    </span>
                                  </div>
                                )}
                              </Button>
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </div>
                {currentAuditData.length === 0 && (
                  <p className="mt-48 text-center font-bold text-lg">
                    No results found. Either date range is missing or search result does not exist
                    ☹️!!
                  </p>
                )}
              </ConditionalLoader>
            )}
          </div>
        </>
      </div>
      <CustomPopup onClose={closePopup} show={showPopup} width={'w-[80vw]'}>
        <div>
          <p className="text-left font-bold text-xl">Federated Identity History:</p>
          <br />
          {federatedData[selectedAuditData] && federatedData[selectedAuditData].length > 0 ? (
            <div className="table-container h-[60vh]">
              <div className="min-w-fit border rounded-md overflow-hidden">
                <table className="text-sm text-left text-gray-500 dark:text-gray-400 ">
                  <thead>
                    <tr className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <th scope="col" className="px-6 py-3 text-center">
                        #
                      </th>
                      {Object.keys(federatedData[selectedAuditData][0]).map((key) => (
                        <th key={key} scope="col" className="px-6 py-3 text-center">
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {federatedData[selectedAuditData].map((dataItem, index) => (
                      <tr key={index}>
                        <td className="px-6 py-3 text-center">{index + 1}</td>
                        {Object.values(dataItem).map((value, key) => (
                          <td key={key} className="px-6 py-3 text-center">
                            {typeof value === 'object' ? JSON.stringify(value) : value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <p className="text-center font-bold">
              {selectedFederatedIdentityStartDate !== null &&
              selectedFederatedIdentityEndDate !== null ? (
                <span>
                  No federated identity history data is available or has been generated ☹️!!
                </span>
              ) : (
                <span>Please enter federated identity date range to display the records !!</span>
              )}
            </p>
          )}
        </div>
      </CustomPopup>
      {selectedAuditDataModule && (
        <CustomPopup
          onClose={() => setShowModuleListPopup(false)}
          show={showModuleListPopup}
          width={'w-41%'}>
          <div>
            <p className="text-left font-bold text-xl">Module List:</p>
            <br />
            {selectedAuditDataModule.userType === UserType.OrganizationUser &&
            selectedAuditDataModule.moduleList.length > 0 ? (
              <div className="table-container h-[60vh]">
                <table className="text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Module Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Module Description
                      </th>
                    </tr>
                  </thead>{' '}
                  <tbody>
                    {selectedAuditDataModule.moduleList.map((module, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td className="px-6 py-4">{module.moduleName}</td>
                        <td className="px-6 py-4">{module.accessType}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-center font-bold">
                {selectedAuditDataModule.userType !== UserType.OrganizationUser && (
                  <span>The module list is accessible only to specific users upon login ☹️!!</span>
                )}
              </p>
            )}
          </div>
        </CustomPopup>
      )}
      <CustomPopup onClose={() => setShowErrorPopup(false)} show={showErrorPopup} width={'w-41%'}>
        <div className="">
          <p className="text-center font-bold text-xl text-red-500">
            Error: Invalid Date Range ☹️!!
          </p>
          <br />
          <p className="text-center text-gray-600">
            Please ensure that the Start Date is before the End Date.
          </p>
        </div>
      </CustomPopup>
      <Pagination
        className={`pagination-bar ${tablePadding} flex justify-center items-center m-auto`}
        currentPage={currentPage}
        totalCount={filteredData.length}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default AuditScreen;
